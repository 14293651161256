import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import ProductThumbnail from "../components/global/productThumbnail"

const ShopPage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO title="Products" />
      <Layout>
        <section className="my-5 py-5">
          <h1 className="my-5 py-5 font-weight-bold display-2 text-center">
            Shop
          </h1>
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <nav>
              <ol className="breadcrumb bg-transparent mx-0 px-0">
                <li className="breadcrumb-item">
                  <Link className="text-muted" to="/">
                    <small>Home</small>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link className="text-muted" to="/shop">
                    <small>Shop</small>
                  </Link>
                </li>
              </ol>
            </nav>
            <p className="text-muted m-0">
              <small>Showing all {data.forProduct.edges.length} results</small>
            </p>
          </div>
          <div className="container-fluid">
            <div className="row">
              {data.forProduct.edges.map(product => {
                return (
                  <div
                    key={product.node.id}
                    className="col-12 col-md-6 col-lg-3"
                  >
                    <ProductThumbnail
                      name={product.node.name}
                      imageThumbnail={product.node}
                      linkToProductPage={`/shop/${product.node.name
                        .toLowerCase()
                        .replace(/ /g, "-")}`}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default ShopPage

export const query = graphql`
  {
    forProduct: allFile(filter: { relativeDirectory: { eq: "shopPage" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
